import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getWarehousesRack } from "../../../../../services/Warehouse";
import { alertOptions } from "../../../../../utils/Utilities";
import TableStocktakeProducts from "./TableStocktakeProducts";

const FormStocktake = ({ productShow, warehouses, reasons, products, onSave, onDelete, onProducts, onAccept, onShowModal, onCompatible }) => {
  const [disabledFields, setDisabledFields] = useState(false);
  const [stocktake, setStocktake] = useState({
    no: "",
    warehouse: "",
    reason: "",
    notes: "",
    products: []
  });

  const handleChange = (evt) => {
    setStocktake({...stocktake, [evt.currentTarget.name]: evt.currentTarget.value});
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (stocktake.warehouse !== "" && stocktake.warehouse !== "default" && stocktake.reason !== "" && stocktake.notes !== "") {
      if (stocktake.products.length > 0) {
        const validateProducts = stocktake.products.filter(prdEle => !prdEle?.check || prdEle?.actual === "");
        if (validateProducts.length === 0) {
          const stocktakeModel = {
            warehouse: { _id: stocktake.warehouse },
            reason: stocktake.reason,
            notes: stocktake.notes,
            productsInventory:
            stocktake.products.map((stocktakeElement) => {
              return ({
                productInventory: { _id: stocktakeElement._id },
                rack: { _id: stocktakeElement.rack },
                expected: stocktakeElement.expected,
                actual: stocktakeElement.actual,
                discrepancy: stocktakeElement.discrepancy,
                notes: stocktakeElement.notes
              });
            })
          };
          onSave(disabledFields, stocktakeModel);
        } else {
          toast.warning("Please check all the products in the table", alertOptions);
        }
      } else {
        toast.warning("Please add at least one product", alertOptions);
      }
    } else {
      toast.info("Please fill in all the fields of the form", alertOptions);
    }
  };

  useEffect(() => {
    setStocktake({...stocktake,
      products: products
    });
  }, [products]);

  useEffect(() => {
    if (productShow) {
      setDisabledFields(true);
      getWarehousesRack(productShow.warehouse._id).then(res => {
        let array_racks = [];
        if (res.status === 200 && res.data.length > 0) array_racks = res.data;

        let newProducts = [];
        for (let i = 0; i < productShow.productStocktake.length; i++) {
          const rackFound = array_racks.find(element => element._id === productShow.productStocktake[i].rack);
          newProducts.push({
            _id: productShow.productStocktake[i]._id,
            rack: rackFound ? rackFound : { _id: productShow.productStocktake[i].rack, name: "" },
            expected: productShow.productStocktake[i].expected,
            actual: productShow.productStocktake[i].actual,
            discrepancy: productShow.productStocktake[i].discrepancy,
            notes: productShow.productStocktake[i].notes,
            data: { productInventory: productShow.productStocktake[i].productInventory }
          });
        }

        setStocktake({...stocktake,
          no: `${String(productShow?._id).substring(String(productShow?._id).length - 3).toLocaleUpperCase()}`,
          warehouse: productShow.warehouse._id,
          reason: productShow.reason,
          notes: productShow.notes,
          products: newProducts
        });
      });
    }
  }, [productShow]);

  return (
    <div className="space-y-5">
      <form className="space-y-3 lg:space-y-5" onSubmit={handleSubmit}>
        <div className="grid gap-3 md:grid-cols-3">
          <div className="w-full flex justify-center items-center">
            <span
              className="w-[35%] md:w-[40%] lg:w-[40%] bg-ag-secondary-dark text-xs lg:text-base text-white text-center border border-gray-500 py-3 lg:py-2 rounded-l-xl"
            >
              Stocktake No.
            </span>

            <input
              className="w-[65%] md:w-[60%] lg:w-[60%] text-ag-secondary-letter border border-gray-500 p-2 rounded-r-xl disabled:bg-zinc-200 truncate"
              type="text"
              name="no"
              value={stocktake.no}
              disabled={true}
            />
          </div>

          <div className="w-full flex justify-center items-center">
            <span
              className="w-[35%] md:w-[40%] lg:w-[40%] bg-ag-secondary-dark text-xs lg:text-base text-white text-center border border-gray-500 py-3 lg:py-2 rounded-l-xl"
            >
              Warehouse
            </span>
            <select
              className="w-[65%] md:w-[60%] lg:w-[60%] text-ag-secondary-letter border border-gray-500 p-2 rounded-r-xl truncate"
              name="warehouse"
              defaultValue="default"
              onChange={handleChange}
              value={stocktake.warehouse}
              disabled={disabledFields}
            >
              <option value="default"></option>
              {
                warehouses.map((element, index) => {
                  return <option key={index} value={element._id}>{element.name}</option>;
                })
              }
            </select>
          </div>

          <div className="w-full flex justify-center items-center">
            <span
              className="w-[35%] md:w-[40%] lg:w-[40%] bg-ag-secondary-dark text-xs lg:text-base text-white text-center border border-gray-500 py-3 lg:py-2 rounded-l-xl"
            >
              Reason
            </span>

            <select
              className="w-[65%] md:w-[60%] lg:w-[60%] text-ag-secondary-letter border border-gray-500 p-2 rounded-r-xl truncate"
              name="reason"
              onChange={handleChange}
              value={stocktake.reason}
              disabled={disabledFields}
            >
              <option value=""></option>
              {
                reasons.map((element, index) => {
                  return <option key={index} value={element.value}>{element.label}</option>;
                })
              }
            </select>
          </div>
        </div>

        <div className="grid gap-3 lg:gap-8 md:grid-cols-5">
          <div className="w-full flex justify-center items-center col-span-3">
            <span
              className="w-[35%] md:w-[40%] lg:w-[22%] bg-ag-secondary-dark text-xs lg:text-base text-white text-center border border-gray-500 py-3 lg:py-2 rounded-l-xl"
            >
              Notes
            </span>

            <input
              className="w-[65%] md:w-[60%] lg:w-[78%] text-ag-secondary-letter border border-gray-500 p-2 rounded-r-xl disabled:bg-zinc-200 truncate"
              type="text"
              name="notes"
              onChange={handleChange}
              value={stocktake.notes}
              disabled={disabledFields}
            />
          </div>

          <div>
            <button
              className="w-full bg-ag-secondary-dark text-white text-xs lg:text-base p-3 lg:p-2 rounded-xl disabled:bg-zinc-200"
              type="button"
              disabled={disabledFields}
              onClick={() => onShowModal(stocktake.warehouse)}
            >
              Add Products
            </button>
          </div>

          <div>
            <button
              className="w-full bg-ag-primary text-white text-xs lg:text-base p-3 lg:p-2 rounded-xl disabled:bg-zinc-200"
              type="submit"
              disabled={disabledFields}
            >
              Save
            </button>
          </div>
        </div>
      </form>

      {
        stocktake.products.length > 0 ?
          <div className="overflow-x-auto">
            <TableStocktakeProducts
              products={stocktake.products}
              disabledFields={disabledFields}
              onProduct={onProducts}
              onAccept={onAccept}
              onDelete={onDelete}
              onCompatible={onCompatible}
            />
          </div>
          : null
      }
    </div>
  );
};

export default FormStocktake;